@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  :root {
    --gametab-card-width: 16px;
    --gblue-900: #0d2a4f;
  }

  .gametab-card {
    position: absolute;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    z-index: 40;
  }

  .gametab-card-t,
  .gametab-card-b {
    width: 100%;
    height: var(--gametab-card-width);
  }

  .gametab-card-r,
  .gametab-card-l {
    width: var(--gametab-card-width);
    height: 100%;
  }

  .gametab-card-t {
    left: 0;
    top: 0;
    transform-origin: center top;
    transform: translateZ(calc(-1 * var(--gametab-card-width))) rotateX(90deg);
  }

  .gametab-card-r {
    right: 0;
    top: 0;
    transform-origin: right center;
    transform: translateZ(calc(-1 * var(--gametab-card-width))) rotateY(90deg);
  }

  .gametab-card-b {
    left: 0;
    bottom: 0;
    transform-origin: center bottom;
    transform: translateZ(calc(-1 * var(--gametab-card-width))) rotateX(-90deg);
  }

  .gametab-card-l {
    left: 0;
    top: 0;
    transform-origin: center left;
    transform: translateZ(calc(-1 * var(--gametab-card-width))) rotateY(-90deg);
  }

  .atropos {
    position: relative;
    display: block;
    perspective: 1200px;
    transform: translate3d(0, 0, 0);
  }
  .atropos-rotate-touch,
  .atropos-rotate-scroll-x,
  .atropos-rotate-scroll-y {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  .atropos-rotate-touch-scroll-y {
    touch-action: pan-y;
  }
  .atropos-rotate-touch-scroll-x {
    touch-action: pan-x;
  }
  .atropos-rotate-touch {
    touch-action: none;
  }
  .atropos-scale,
  .atropos-rotate {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition-property: transform;
    display: block;
  }
  .atropos-shadow,
  .atropos-highlight {
    position: absolute;
    pointer-events: none;
    transition-property: transform, opacity;
    display: block;
    opacity: 0;
  }
  .atropos-shadow {
    z-index: -1;
    background: #000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    filter: blur(30px);
  }
  .atropos-highlight {
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-image: radial-gradient(
      circle at 50%,
      rgba(255, 255, 255, 0.25),
      transparent 50%
    );
    z-index: 0;
  }
  .atropos-rotate {
    position: relative;
  }
  .atropos-inner {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    transform-style: preserve-3d;
    transform: translate3d(0, 0, 0);
    display: block;
  }
  .atropos-active {
    z-index: 1;
  }
  .atropos-active .atropos-shadow {
    opacity: 1 !important;
  }
  [data-atropos-offset],
  ::slotted([data-atropos-offset]) {
    transition-property: transform;
  }
  [data-atropos-opacity] {
    transition-property: opacity;
  }
  [data-atropos-offset][data-atropos-opacity],
  ::slotted([data-atropos-offset][data-atropos-opacity]) {
    transition-property: transform, opacity;
  }

  .loadbeeTabContent {
    overflow: hidden;
    transition: max-height 0.5s ease;
  }

  #checkstroke {
    stroke-dasharray: 13.2;
    stroke-dashoffset: -13.2;
    animation: animateDash 300ms ease forwards;
    animation-delay: 200ms;
  }

  @keyframes animateDash {
    100% {
      stroke-dashoffset: 0;
    }
  }

  .highlight span {
    font-weight: 700;
    user-select: none;
  }

  .pyro > .before,
  .pyro > .after {
    position: absolute;
    z-index: 100;
    top: 50px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow:
      -120px -218.66667px blue,
      248px -16.66667px #00ff84,
      190px 16.33333px #002bff,
      -113px -308.66667px #ff009d,
      -109px -287.66667px #ffb300,
      -50px -313.66667px #ff006e,
      226px -31.66667px #ff4000,
      180px -351.66667px #ff00d0,
      -12px -338.66667px #00f6ff,
      220px -388.66667px #99ff00,
      -69px -27.66667px #ff0400,
      -111px -339.66667px #6200ff,
      155px -237.66667px #00ddff,
      -152px -380.66667px #00ffd0,
      -50px -37.66667px #00ffdd,
      -95px -175.66667px #a6ff00,
      -88px 10.33333px #0d00ff,
      112px -309.66667px #005eff,
      69px -415.66667px #ff00a6,
      168px -100.66667px #ff004c,
      -244px 24.33333px #ff6600,
      97px -325.66667px #ff0066,
      -211px -182.66667px #00ffa2,
      236px -126.66667px #b700ff,
      140px -196.66667px #9000ff,
      125px -175.66667px #00bbff,
      118px -381.66667px #ff002f,
      144px -111.66667px #ffae00,
      36px -78.66667px #f600ff,
      -63px -196.66667px #c800ff,
      -218px -227.66667px #d4ff00,
      -134px -377.66667px #ea00ff,
      -36px -412.66667px #ff00d4,
      209px -106.66667px #00fff2,
      91px -278.66667px #000dff,
      -22px -191.66667px #9dff00,
      139px -392.66667px #a6ff00,
      56px -2.66667px #0099ff,
      -156px -276.66667px #ea00ff,
      -163px -233.66667px #00fffb,
      -238px -346.66667px #00ff73,
      62px -363.66667px #0088ff,
      244px -170.66667px #0062ff,
      224px -142.66667px #b300ff,
      141px -208.66667px #9000ff,
      211px -285.66667px #ff6600,
      181px -128.66667px #1e00ff,
      90px -123.66667px #c800ff,
      189px 70.33333px #00ffc8,
      -18px -383.66667px #00ff33,
      100px -6.66667px #ff008c;
    animation:
      1s bang ease-out infinite backwards,
      1s gravity ease-in infinite backwards,
      5s position linear infinite backwards,
      6s end ease-in forwards;
  }

  .pyro > .after {
    animation-delay: 1.25s, 1.25s, 1.25s;
    animation-duration: 1.25s, 1.25s, 6.25s;
  }

  @keyframes end {
    99% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes bang {
    from {
      box-shadow:
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white,
        0 0 white;
    }
  }

  @keyframes gravity {
    to {
      transform: translateY(200px);
      opacity: 0;
    }
  }

  @keyframes position {
    0%,
    19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }

    20%,
    39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }

    40%,
    59.9% {
      margin-top: 20%;
      margin-left: 70%;
    }

    60%,
    79.9% {
      margin-top: 30%;
      margin-left: 20%;
    }

    80%,
    99.9% {
      margin-top: 30%;
      margin-left: 80%;
    }
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .styled-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgb(13 42 79 / 0.1) transparent;
    scrollbar-gutter: stable;
  }

  html {
    --scroll-behavior: smooth !important;
    scroll-behavior: smooth !important;

    /* https://github.com/tailwindlabs/headlessui/issues/762 */
    padding-right: 0 !important;
  }

  input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
  }
  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 2px;
  }
  input[type='range']::-moz-range-track {
    -moz-appearance: none;
    height: 2px;
  }
  input[type='range']::-ms-track {
    appearance: none;
    height: 2px;
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    background-color: var(--gblue-900);
    cursor: pointer;
    margin-top: -8px;
    pointer-events: auto;
    border-radius: 50%;
    border: 0;
  }
  input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    cursor: pointer;
    border-radius: 50%;
    border: 0;
    background-color: var(--gblue-900);
    pointer-events: auto;
  }
  input[type='range']::-ms-thumb {
    appearance: none;
    height: 18px;
    width: 18px;
    cursor: pointer;
    border-radius: 50%;
    border: 0;
    background-color: var(--gblue-900);
    pointer-events: auto;
  }
  input[type='range']:active::-ms-thumb {
    transform: scale(1.2);
  }
  input[type='range']:active::-moz-range-thumb {
    transform: scale(1.2);
  }
  input[type='range']:active::-webkit-slider-thumb {
    transform: scale(1.2);
  }

  .styled-scrollbar-without-gutter {
    scrollbar-color: rgba(13, 42, 79, 0.1) transparent;
    scrollbar-width: thin;
  }

  @page {
    size: A4;
    margin: 0;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  @media print {
    .no-print {
      display: none;
    }
    .header-print {
      border-bottom: #0d2a4f 1px solid;
    }
    html {
      background-color: white !important;
    }
  }
}

/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */
